import watchElements from './utils/watchElements'
import { animatedComponent } from './utils/AnimatedValue'
import 'ellipsis.js'

watchElements(
  '[data-ellipsis]',
  {
    attributeFilter: ['data-ellipsis']
  },
  animatedComponent(
    (el) => JSON.parse(el.dataset.ellipsis || '{}'),
    (el, args, mounted) => {
      const ellipsis = window.Ellipsis(args?.value)
      ellipsis.addElement(el)
      mounted.addListener((mounted) => {
        if (!mounted) {
          ellipsis.destroy()
        }
      })
    }
  )
)
