import ResizeObserver from 'resize-observer-polyfill'

const watchElements = <T extends Element = Element>(
  filter: string,
  options: MutationObserverInit & { watchResize?: boolean },
  callbacks: {
    mount?: (
      el: T
    ) => void | {
      update?: () => void
      unmount?: () => void
    }
    update?: (el: T) => void
    unmount?: (el: T) => void
  } = {}
): void => {
  const elements = new WeakMap<
    Element,
    {
      update?: () => void
      unmount?: () => void
    }
  >()
  const resizeObserver = options.watchResize
    ? new ResizeObserver((entries) => {
        for (const entry of entries) {
          const cbs = elements.get(entry.target)
          if (cbs) {
            cbs.update && cbs.update()
            callbacks.update && callbacks.update(entry.target as T)
          }
        }
      })
    : undefined
  const handleNode = (
    node: Node,
    checkChildren = false,
    isDeletion = false
  ) => {
    if (node.nodeType !== 1) {
      return
    }
    const el = node as Element
    const matches = !isDeletion && el.matches(filter)
    const cbs = elements.get(el)
    const isNew = !cbs
    if (matches && isNew) {
      if (callbacks.mount) {
        elements.set(el, callbacks.mount(el as T) || {})
      } else {
        callbacks.update && callbacks.update(el as T)
        elements.set(el, {})
      }
      resizeObserver && resizeObserver.observe(el)
    } else if (matches && !isNew) {
      const { update } = cbs || {}
      update && update()
      callbacks.update && callbacks.update(el as T)
    } else if (!matches && !isNew) {
      const { unmount } = cbs || {}
      elements.delete(el)
      resizeObserver && resizeObserver.unobserve(el)
      unmount && unmount()
      callbacks.unmount && callbacks.unmount(el as T)
    }
    if (checkChildren) {
      for (const element of el.querySelectorAll(filter)) {
        handleNode(element, false, isDeletion)
      }
    }
    if (options.subtree) {
      let parent = el.parentElement
      while (parent) {
        if (parent.matches(filter)) {
          handleNode(parent)
        }
        parent = parent.parentElement
      }
    }
  }
  const observer = new MutationObserver((entries) => {
    for (const entry of entries) {
      handleNode(entry.target)
      for (const node of entry.addedNodes) {
        handleNode(node, true)
      }
      for (const node of entry.removedNodes) {
        handleNode(node, true, true)
      }
    }
  })
  for (const el of document.querySelectorAll(filter)) {
    handleNode(el)
  }
  const allAttributes = (options.attributeFilter || []).includes('*')
  const observerOptions = allAttributes
    ? {
        attributes: true,
        childList: true,
        subtree: true
      }
    : {
        attributeFilter: ['class', 'id', ...(options.attributeFilter || [])],
        attributes: true,
        childList: true,
        subtree: true
      }
  observer.observe(document.body, Object.assign({}, options, observerOptions))
}

export default watchElements
