// force is not available on IE11 hence this function
const toggleClass = (
  el?: HTMLElement,
  className?: string,
  force?: boolean
): void => {
  if (!el || !className) {
    return
  }
  if (typeof force === undefined) {
    el.classList.toggle(className)
  }
  if (force && !el.classList.contains(className)) {
    el.classList.add(className)
  } else if (!force && el.classList.contains(className)) {
    el.classList.remove(className)
  }
}

export default toggleClass
