import 'intersection-observer'
import 'core-js/es6/promise'

import 'core-js/fn/array/includes'
import 'core-js/fn/number/is-nan'
import 'core-js/fn/string/ends-with'
import 'core-js/fn/string/pad-start'
import 'core-js/fn/object/assign'

if (!Element.prototype.matches) {
  Element.prototype.matches =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function (this: Element, selector: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let el: (Node & ParentNode) | null = this

    do {
      if (Element.prototype.matches.call(el, selector)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

// Source: https://github.com/jserz/js_piece/blob/master/DOM/ParentNode/prepend()/prepend().md
;(function (arr) {
  arr.forEach(function (item) {
    if (item.hasOwnProperty('prepend')) {
      return
    }
    Object.defineProperty(item, 'prepend', {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function prepend() {
        // eslint-disable-next-line prefer-rest-params
        const argArr = Array.prototype.slice.call(arguments),
          docFrag = document.createDocumentFragment()

        argArr.forEach(function (argItem) {
          const isNode = argItem instanceof Node
          docFrag.appendChild(
            isNode ? argItem : document.createTextNode(String(argItem))
          )
        })

        this.insertBefore(docFrag, this.firstChild)
      }
    })
  })
})([Element.prototype, Document.prototype, DocumentFragment.prototype])
;(function () {
  if (typeof window.CustomEvent === 'function') return false //If not IE

  function CustomEvent<T>(
    event: string,
    params: CustomEventInit<T> & { bubbles: boolean; cancelable: boolean }
  ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    const evt = document.createEvent('CustomEvent')
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = (CustomEvent as unknown) as typeof window.CustomEvent
})()
