import watchElements from './utils/watchElements'
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
import 'bootstrap-select'

watchElements<HTMLFormElement>(
  'form[data-parsley-validate]',
  {},
  {
    mount(el) {
      import('./parsley').then(({ init }) => {
        init(el)
      })
    }
  }
)
