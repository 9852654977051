import 'parsleyjs'

type Product = { typo3Id: number; name: string; serialSuffixes: string[] }
const products: Product[] = [
  {
    typo3Id: 1,
    name: 'Pico Flexx',
    serialSuffixes: ['0883', '0884', '0885', '0886']
  },
  {
    typo3Id: 2,
    name: 'Pico Monstar',
    serialSuffixes: ['8820', '0821', '0822', '0823']
  },
  { typo3Id: 3, name: 'Other', serialSuffixes: ['0830', '0831', '0832'] }
]

const isValidSerial = (
  serial: string,
  selectedProduct: Product | undefined
): boolean => {
  if (!selectedProduct) {
    return false
  }
  for (const suffix of selectedProduct.serialSuffixes) {
    if (serial.endsWith(suffix)) {
      return true
    }
  }
  return false
}

const getFormValue = (
  form: HTMLFormElement | undefined | null,
  suffix: string
): FormDataEntryValue | undefined => {
  if (!form) {
    return undefined
  }
  // Get the data that would be posted to the Server as an array of key/value-pairs
  const data = Array.from(new FormData(form).entries())
  // Find the entry with the correct suffix
  const target = data.find(([key]) => key.endsWith(`[${suffix}]`))
  // Return the value
  return target?.[1]
}

window.Parsley.addValidator('validSerial', {
  requirementType: 'string',
  validateString(serial, fieldname, field) {
    const form = field.element.closest('form')
    const productId = Number(getFormValue(form, fieldname) ?? -1)
    const product = products.find(({ typo3Id }) => typo3Id === productId)
    return isValidSerial(serial, product)
  },
  messages: {
    en: 'The entered serialnumber is invalid'
  }
})
