const toNumber = (input: unknown, defaultValue = 0): number => {
  if (typeof input === 'number') {
    return input
  }
  if (input === '') {
    return defaultValue
  }
  if (typeof input === 'string' || typeof input === 'boolean') {
    const parsed = Number(input)
    if (!isNaN(parsed)) {
      return parsed
    }
  }
  return defaultValue
}

export default toNumber
