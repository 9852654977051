const getEdgeInformation = (): {
  readonly isEdge: boolean
  readonly isDeprecatedEdge: boolean
  readonly isChromiumEdge: boolean
  readonly version: number
} => {
  const match = navigator.userAgent.match(/Edge\/([\d\.]+)/)
  if (!match) {
    return {
      isEdge: false,
      isDeprecatedEdge: false,
      isChromiumEdge: false,
      version: Infinity
    }
  }
  const version = parseFloat(match[1])
  return {
    isEdge: true,
    isDeprecatedEdge: version < 18.18363, // Edge 44
    isChromiumEdge: version > 19,
    version
  }
}

export const EDGE_INFORMATION = getEdgeInformation()
// console.log(EDGE_INFORMATION)
