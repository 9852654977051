
function animateValue(id:string, start:number, end:number, duration:number) {
    
    let obj = document.getElementById(id);
    var range = end - start;
    var current = start;

    var increment = end > start? 1 : -1;
    var stepTime = Math.abs(Math.floor(duration / range));
    var timer = setInterval(function() {
        current += increment;
        var val = current < 10 ? '0'+ current : current;

        if(obj != null) {
            obj.textContent = val.toString();
        }
        if (current == end) {
            clearInterval(timer);
        }
    }, stepTime);
}

var numbers = document.querySelectorAll('.party-counter .count-number');

for (let i = 0; i < numbers.length; i++) {
    
    const number = numbers[i];
    const start = parseInt(number.getAttribute('data-start')!);
    const end = parseInt(number.getAttribute('data-end')!);

    animateValue(number.id,start,end,2000);
}

const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
  "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
];

const presentYear = <HTMLElement>document.getElementById('present-year');
const presentDay = <HTMLElement>document.getElementById('present-day');
const presentMonth = <HTMLElement>document?.getElementById('present-month');
const presentHours = <HTMLElement>document.getElementById('present-hour');
const presentMin = <HTMLElement>document.getElementById('present-min');
const amPm = <HTMLElement>document.querySelector('.ampm');


function setTime(val:number) {
   const time = val < 10 ? '0'+ val.toString() : val.toString();

    return time
}

function timeUpdate() {

    const getDate = () => {
        var currentdate = new Date();
        var hours = currentdate.getHours();
        var ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12;
        const timeStamp = {
            day: currentdate.getDate(),
            month: monthNames[currentdate.getMonth()],
            year: currentdate.getFullYear(),
            hours: hours,
            minutes:currentdate.getMinutes(),
            ampm: ampm
        }
            return timeStamp
    }

    if(presentMonth){
        presentMonth.textContent = getDate().month;
    }
    if(presentHours) {
        presentHours.textContent = setTime(getDate().hours);
    }
    if(amPm) {
        amPm.textContent = getDate().ampm;
    }
    if(presentMin) {
        presentMin.textContent = setTime(getDate().minutes);
    }
    if(presentDay) {
        presentDay.textContent = setTime(getDate().day);
    }
    if(presentYear) {
        presentYear.textContent = getDate().year.toString();
    }
}

timeUpdate();
setInterval(timeUpdate,1000);