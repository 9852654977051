import watchElements from './utils/watchElements'
import fit from './utils/fit'
import { Style, setStyle } from './utils/setStyle'
import {
  animatedComponent,
  AnimatedValue,
  InterpolatedValue
} from './utils/AnimatedValue'
import watchBcr from './utils/animated/watchBcr'
import toNumber from './utils/toNumber'

const nb = (string: string): number => {
  return Number(string.replace(/[^\d\.]/g, ''))
}

const fitCSS = (...args: Parameters<typeof fit>): Partial<Style> => {
  const {
    size: { width, height },
    offset: { top, left }
  } = fit(...args)
  return {
    width: width + 'px',
    height: height + 'px',
    left: left + 'px',
    top: top + 'px'
  }
}

const getOffsetParent = (el?: HTMLElement): Element | null => {
  if (el?.offsetParent) {
    return el.offsetParent
  }
  if (el && el.parentElement) {
    if (
      ['absolute', 'fixed'].includes(
        getComputedStyle(el.parentElement).position
      )
    ) {
      return el.parentElement
    }
    return getOffsetParent(el.parentElement)
  }
  return null
}

const getNaturalSize = (img: HTMLImageElement | HTMLVideoElement) => {
  if (img instanceof HTMLVideoElement) {
    return {
      width: img.videoWidth,
      height: img.videoHeight
    }
  }
  return {
    width: img.naturalWidth || nb(img.getAttribute('width') || '0'),
    height: img.naturalHeight || nb(img.getAttribute('height') || '0')
  }
}

watchElements<HTMLImageElement | HTMLVideoElement>(
  'img[data-fit], video[data-fit]',
  {
    attributeFilter: ['data-fit', 'data-focus-point']
  },
  {
    mount(el) {
      const parent = getOffsetParent(el) as HTMLElement
      if (!parent) {
        console.warn('No offsetParent for imgFit-element: ', el)
        return
      }
      const mounted = new AnimatedValue(true)

      setStyle(el, {
        position: 'absolute'
      })
      const update = () => {
        const dataFocusPoint = (el.dataset.focusPoint || '')
          .split(',')
          .map((x) => x.trim())
        const focusPoint = {
          x: toNumber(dataFocusPoint[0], 50) / 100,
          y: toNumber(dataFocusPoint[1], 50) / 100
        }

        const style = getComputedStyle(parent)
        const width =
          parent.offsetWidth - nb(style.paddingLeft) - nb(style.paddingRight)
        const height =
          parent.offsetHeight - nb(style.paddingTop) - nb(style.paddingBottom)
        const elStyle = fitCSS(
          el.dataset.fit as 'cover' | 'contain',
          { width, height },
          getNaturalSize(el),
          focusPoint,
          { left: nb(style.paddingLeft), top: nb(style.paddingTop) }
        )
        setStyle(el, {
          position: 'absolute',
          ...elStyle
        })
      }
      watchBcr(parent, mounted).addListener(update)
      const onLoad = () => update()
      el.addEventListener('load', onLoad)
      ;(el as HTMLVideoElement).addEventListener('loadedmetadata', onLoad)
      ;(el as HTMLVideoElement).addEventListener('loadeddata', onLoad)
      ;(el as HTMLVideoElement).addEventListener('canplay', onLoad)

      return {
        update,
        unmount: () => {
          mounted.value = false
          el.removeEventListener('load', onLoad)
          ;(el as HTMLVideoElement).removeEventListener(
            'loadedmetadata',
            onLoad
          )
          ;(el as HTMLVideoElement).removeEventListener('loadeddata', onLoad)
          ;(el as HTMLVideoElement).removeEventListener('canplay', onLoad)
        }
      }
    }
  }
)

watchElements<HTMLImageElement>(
  '.collage1 img, .collage2 img',
  {},
  animatedComponent(
    () => ({}),
    (el, args, mounted, addEventListener) => {
      const width = watchBcr(el, mounted).interpolate((x) => x?.width || 0)
      const dimensions = new AnimatedValue(getNaturalSize(el))
      addEventListener(el)('load', () => {
        dimensions.value = getNaturalSize(el)
      })
      const height = new InterpolatedValue(
        { width, dimensions },
        ({ width, dimensions }) => {
          return (dimensions.height / dimensions.width) * width
        }
      )

      height.addListener((height) => {
        setStyle(el, { height: height + 'px' })
      })
    }
  )
)
