import { Observable, AnimatedValue, InterpolatedValue } from '../AnimatedValue'

const watchVisibility = <T extends Element>(
  _el: T | Observable<T | undefined> | Observable<T>,
  mounted: Observable<boolean>
): AnimatedValue<boolean> => {
  const el = _el instanceof Element ? new AnimatedValue(_el) : _el
  const element = new InterpolatedValue(
    { el: el as Observable<T | undefined>, mounted },
    ({ el, mounted }) => {
      if (!mounted || !el || el instanceof Error) {
        return undefined
      }
      return el
    }
  )
  const isVisible = (el: Element | undefined) => {
    if (!el || el instanceof Error) {
      return false
    }
    const bcr = el.getBoundingClientRect()
    return bcr.top <= window.innerHeight && bcr.bottom >= 0
  }

  const visible = new AnimatedValue<boolean>(isVisible(el.value))
  const observer = new IntersectionObserver((entries) => {
    visible.value = entries.some((x) => x.isIntersecting)
  }, {})
  element.addListener((el) => {
    visible.value = isVisible(el)
    if (el && !(el instanceof Error)) {
      observer.observe(el)
      return () => observer.unobserve(el)
    }
  }, true)
  return visible
}

export default watchVisibility
