import watchElements from './utils/watchElements'
import { setStyle } from './utils/setStyle'

const precisionX = 5
const precisionY = 5

watchElements<HTMLElement>(
  '.bg-dot-pattern',
  { watchResize: true },
  {
    update(el) {
      const { width, height } = el.getBoundingClientRect()
      const desiredWidth = Math.floor(width / precisionX) * precisionX
      const desiredHeight = Math.floor(height / precisionY) * precisionY
      setStyle(el, {
        paddingRight: width - desiredWidth + 'px',
        paddingBottom: height - desiredHeight + 'px'
      })
    }
  }
)
