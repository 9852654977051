import watchElements from '../utils/watchElements'
import { animatedComponent } from '../utils/AnimatedValue'

watchElements(
  '.lottie',
  {
    attributeFilter: ['data-src', 'data-loop', 'data-autoplay']
  },
  animatedComponent<
    {
      src?: string
      loop: boolean
      autoplay: boolean
    },
    HTMLElement
  >(
    (el) => ({
      src: el.dataset.src,
      loop: 'loop' in el.dataset,
      autoplay: 'autoplay' in el.dataset
    }),
    () => import('./lottie').then((x) => x.lottieEl)
  )
)
