import $ from 'jquery';

	$('.accordion .accordion-panel').on('click', '.accordion__header', function() {
		$(this).parent('.accordion-panel').siblings('.accordion-panel').children('.accordion__body').slideUp().removeClass('flipInX');
        $(this).parent().siblings().find('.accordion__header .ic-arrow-right').css('transform','rotate(0deg)');
		
		if($(this).next().is(':hidden')) {
			$(this).next().slideDown().addClass('flipInX');
            $(this).find('.ic-arrow-right').css('transform','rotate(90deg)');
		} else {
			
			$(this).next().slideUp();
			$(this).find('.accordion__button').addClass('fa-plus');
            $(this).find('.ic-arrow-right').css('transform','rotate(0deg)');
		}
	});
