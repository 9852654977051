import Shuffle from './utils/Shuffle'
import toggleClass from './utils/toggleClass'

const parseHTML = async (response: Response): Promise<Document> => {
  const html = await response.text()
  return new DOMParser().parseFromString(html, 'text/html')
}

const extractPosts = (archiveID: string) => (
  doc: Document
): Promise<HTMLElement[]> =>
  new Promise((resolve, reject) => {
    const wrap = doc.querySelector<HTMLElement>(`#${archiveID} .card-deck`)
    if (!wrap) {
      reject('No archive found.')
    }
    resolve(Array.from(wrap!.children) as HTMLElement[])
  })

for (const blogArchive of document.querySelectorAll('.blog-archive')) {
  const cardDeck = blogArchive.querySelector<HTMLElement>('.card-deck')
  const id = blogArchive.getAttribute('id')
  if (!cardDeck || !id) {
    continue
  }
  const shuffle = new Shuffle(cardDeck)

  const getPosts = async (
    uri: string
  ): Promise<{
    posts: HTMLElement[]
    navigation: HTMLElement | null
    title: string
  }> => {
    const doc = await fetch(uri).then(parseHTML)
    const posts = await extractPosts(id)(doc)
    const navigation = doc.querySelector<HTMLElement>(`#${id} .blog-navigation`)
    return {
      posts,
      navigation,
      title: doc.title
    }
  }

  const replaceNavigation = (navigation: HTMLElement | null) => {
    for (const el of blogArchive.querySelectorAll('.blog-navigation')) {
      if (el.parentElement === blogArchive) {
        blogArchive.removeChild(el)
      }
    }
    if (navigation) {
      blogArchive.appendChild(navigation)
    }
  }

  const updateUri = (url: string, title: string): void => {
    document.title = title
    history.replaceState(null, title, url)
  }

  const replaceFromUri = async (uri: string): Promise<void> => {
    const { posts, navigation, title } = await getPosts(uri)
    for (const link of blogArchive.querySelectorAll<HTMLAnchorElement>(
      '.nav-tags a'
    )) {
      toggleClass(
        link,
        'active',
        link.href.split('page-')[0] === uri.split('page-')[0]
      )
    }
    updateUri(uri, title)
    shuffle.setChildren(posts)
    replaceNavigation(navigation)
  }

  const addFromUri = async (uri: string): Promise<void> => {
    const { posts, navigation, title } = await getPosts(uri)
    updateUri(uri, title)
    shuffle.addChildren(posts)
    replaceNavigation(navigation)
  }

  blogArchive.addEventListener('click', (e) => {
    const a = (e.target as HTMLElement).closest('a')
    if (a) {
      if (a.closest('.nav-tags')) {
        e.preventDefault()
        if (!a.classList.contains('active')) {
          replaceFromUri(a.href)
        }
      }
      if (a.classList.contains('blog-load-next')) {
        e.preventDefault()
        addFromUri(a.href)
      }
      if (a.classList.contains('blog-load-replace')) {
        e.preventDefault()
        replaceFromUri(a.href)
      }
    }
  })
}
