export type AnimationFrameListener<T> = {
  measure?: () => T
  update?: (value: T) => boolean | void
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const listeners = new Set<AnimationFrameListener<any>>()
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let _listeners: AnimationFrameListener<any>[] = []
const onAnimationFrame = () => {
  const values = []
  const oldListeners = []
  for (let i = 0; i < _listeners.length; i++) {
    values[i] = _listeners[i].measure ? _listeners[i].measure!() : undefined
  }
  for (let i = 0; i < _listeners.length; i++) {
    if (_listeners[i].update && _listeners[i].update!(values[i]) === false) {
      oldListeners.push(_listeners[i])
    }
  }
  for (const listener of oldListeners) {
    listeners.delete(listener)
  }
  _listeners = Array.from(listeners.values())
  requestAnimationFrame(onAnimationFrame)
}
requestAnimationFrame(onAnimationFrame)

export const onMeasure = <T>(listener: AnimationFrameListener<T>): void => {
  listeners.add(listener)
  _listeners = Array.from(listeners.values())
}

export const offMeasure = <T>(listener: AnimationFrameListener<T>): void => {
  listeners.delete(listener)
  _listeners = Array.from(listeners.values())
}

export const onUpdate = (fn: () => void): void => {
  onMeasure({
    update: () => {
      fn()
      return false
    }
  })
}
