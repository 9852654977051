import watchElements from './utils/watchElements'
import {
  animatedComponent,
  AnimatedValue,
  InterpolatedValue
} from './utils/AnimatedValue'
import watchElementSize from './utils/animated/watchElementSize'
import Shuffle from './utils/Shuffle'
import toggleClass from './utils/toggleClass'

watchElements(
  '.collapse-cards',
  {
    attributeFilter: ['class', 'data-min-rows', 'data-min-items']
  },
  animatedComponent(
    (el) => ({
      minRows: Number(el.dataset.minRows) || 2,
      minItems: Number(el.dataset.minItems) || 1,
      wrap: el.querySelector<HTMLElement>('.row') || undefined,
      btn: el.querySelector<HTMLElement>('.collapse-cards-link') || undefined
    }),
    (el, args, mounted) => {
      const expanded = new AnimatedValue(false)
      new InterpolatedValue(
        { btn: args.interpolate((x) => x.btn), mounted },
        ({ btn, mounted }) => (mounted ? btn : undefined)
      ).addListener((btn) => {
        if (btn) {
          const onClick = (e: MouseEvent) => {
            e.preventDefault()
            expanded.value = true
          }
          btn.addEventListener('click', onClick)
          return () => btn.removeEventListener('click', onClick)
        }
      }, true)
      const wrap = new InterpolatedValue(
        { args, mounted },
        ({ args, mounted }) => (mounted ? args.wrap : undefined)
      )
      const shuffle = wrap.interpolate((x) => x && new Shuffle(x))
      const items = new InterpolatedValue({ wrap }, ({ wrap }) => {
        return wrap ? (Array.from(wrap.children) as HTMLElement[]) : []
      })
      const itemsPerRow = new InterpolatedValue(
        {
          wrapWidth: watchElementSize(wrap).interpolate((x) => x.width),
          itemWidth: watchElementSize(
            items.interpolate((items): Element | undefined => items[0])
          ).interpolate((x) => x.width + 48)
        },
        ({ wrapWidth, itemWidth }) => {
          return Math.round(wrapWidth / itemWidth)
        }
      )
      new InterpolatedValue(
        { shuffle, items, itemsPerRow, expanded, args },
        (x) => ({ ...x })
      ).addListener(({ shuffle, items, itemsPerRow, expanded, args }, old) => {
        if (!shuffle) {
          return
        }
        const minItems = Math.max(itemsPerRow * args.minRows, args.minItems)
        const visibleItems = []
        let fixedItemsCount = items.filter((x) =>
          x.classList.contains('show-always')
        ).length
        for (const item of items) {
          if (expanded) {
            visibleItems.push(item)
          } else if (item.classList.contains('show-always')) {
            visibleItems.push(item)
            fixedItemsCount--
          } else if (visibleItems.length + fixedItemsCount < minItems) {
            visibleItems.push(item)
          }
        }
        shuffle.setChildren(
          visibleItems,
          old ? expanded === old.expanded : true
        )
        toggleClass(args.btn, 'd-none', visibleItems.length === items.length)
      }, true)
    }
  )
)
