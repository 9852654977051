import watchElements from './utils/watchElements'
import {
  animatedComponent,
  AnimatedValue,
  InterpolatedValue
} from './utils/AnimatedValue'
import watchVisibility from './utils/animated/watchVisibility'

const TYPE_SPEED = 100
const DELETION_SPEED = 50
const PAUSE = 1500

watchElements(
  '[data-type-alternatives]',
  {
    attributeFilter: ['data-type-alternatives']
  },
  animatedComponent(
    (el) => ({ alternatives: el.dataset.typeAlternatives }),
    (el, args, mounted) => {
      const visibility = watchVisibility(el, mounted)
      const originalText = el.textContent
      const alternatives = args.interpolate(({ alternatives }) => {
        const result = new Set<string>(
          ((alternatives && JSON.parse(alternatives)) || []).map((x: string) =>
            x.toString().trim()
          )
        )
        if (originalText) result.add(originalText.trim())
        return Array.from(result)
      })
      const wordIndex = new AnimatedValue(0)
      const currentWord = new InterpolatedValue(
        { alternatives, wordIndex },
        ({ alternatives, wordIndex }) =>
          alternatives[Math.max(0, wordIndex % alternatives.length)]
      )
      const charIndex = new AnimatedValue(0)
      const currentText = new InterpolatedValue(
        { currentWord, charIndex },
        ({ currentWord, charIndex }) =>
          currentWord.substring(0, Math.max(0, charIndex))
      )

      let timeout: NodeJS.Timeout | undefined
      const onDeleteTick = () => {
        if (charIndex.value > 0) {
          charIndex.value--
          timeout = setTimeout(onDeleteTick, DELETION_SPEED)
        } else {
          wordIndex.value = (wordIndex.value + 1) % alternatives.value.length
          timeout = setTimeout(onTypeTick, TYPE_SPEED)
        }
      }
      const onTypeTick = () => {
        if (charIndex.value < currentWord.value.length) {
          charIndex.value++
          setTimeout(onTypeTick, TYPE_SPEED)
        } else {
          setTimeout(onDeleteTick, PAUSE)
        }
      }

      timeout = setTimeout(onTypeTick, TYPE_SPEED)

      new InterpolatedValue(
        { currentText, visibility },
        ({ currentText, visibility }) => (visibility ? currentText : undefined)
      ).addListener((text) => {
        if (typeof text !== 'undefined') {
          el.textContent = text
        }
      })

      return () => {
        clearTimeout(timeout as number | undefined)
        el.textContent = originalText
      }
    }
  )
)
