import { setStyle, Style } from './utils/setStyle'
import watchElements from './utils/watchElements'
import {
  animatedComponent,
  AnimatedValue,
  InterpolatedValue,
  smoothen
} from './utils/AnimatedValue'
import { EDGE_INFORMATION } from './utils/edge'

const isEnabled = !EDGE_INFORMATION.isDeprecatedEdge

const getFocusParent = (el: HTMLElement): HTMLElement | undefined => {
  if (el.tabIndex >= 0) {
    return el
  }
  if (el.parentElement) {
    return getFocusParent(el.parentElement)
  }
  return undefined
}

if (isEnabled) {
  document.body.classList.add('tilt3d-enabled')

  watchElements<HTMLElement>(
    '.tilt3d',
    {},
    animatedComponent<undefined>(
      undefined,
      (el, _args, _mounted, addEventListener) => {
        for (const child of el.querySelectorAll('.tilt3d-shadow')) {
          el.removeChild(child)
        }
        const shadow = document.createElement('div')
        shadow.classList.add('tilt3d-shadow')
        el.prepend(shadow)

        const mouseXPct = new AnimatedValue(0) // pct from center
        const mouseYPct = new AnimatedValue(0) // pct from center
        const hover = new AnimatedValue(false)
        const focus = new AnimatedValue(false)
        const active = new AnimatedValue(false)

        const onMouseMove = (event: MouseEvent | TouchEvent): void => {
          const { top, left, width, height } = el.getBoundingClientRect()

          const position =
            'clientX' in event
              ? [event.clientX, event.clientY]
              : [event.touches[0].clientX, event.touches[0].clientY]

          const mouseX = width / 2 - (position[0] - left)
          mouseXPct.value = Math.max(-0.5, Math.min(0.5, (mouseX / width) * 2))
          const mouseY = height / 2 - (position[1] - top)
          mouseYPct.value = Math.max(-0.5, Math.min(0.5, (mouseY / height) * 2))
        }

        const degX = smoothen(
          new InterpolatedValue(
            { mouseYPct, hover, active },
            ({ mouseYPct, hover, active }): number => {
              return hover && !active ? mouseYPct * 5 : 0
            }
          )
        )
        const degY = smoothen(
          new InterpolatedValue(
            { mouseXPct, hover, active },
            ({ mouseXPct, hover, active }): number => {
              return hover && !active
                ? Math.max(-4, Math.min(4, mouseXPct * -10))
                : 0
            }
          )
        )
        const translate = smoothen(
          new InterpolatedValue(
            { hover, focus, active },
            ({ hover, focus, active }) =>
              (hover || focus) && !active ? 1 : (0 as number)
          )
        )

        const style = new InterpolatedValue(
          { degX, degY, translate },
          ({ degX, degY, translate }): Partial<Style> => ({
            transform: [
              `perspective(600px)`,
              `translate3d(${translate * -5}px,${translate * -5}px,${
                translate * 20
              }px)`,
              `rotateX(${degX}deg)`,
              `rotateY(${degY}deg)`
            ].join(' ')
          })
        )

        const shadowStyle = new InterpolatedValue(
          { translate },
          ({ translate }): Partial<Style> => ({
            transform: [
              `perspective(600px)`,
              `translate3d(${translate * 10}px,${translate * 10}px,${-10}px)`
            ].join(' ')
          })
        )

        style.addListener((style) => setStyle(el, style))
        shadowStyle.addListener((style) => setStyle(shadow, style))

        const on = addEventListener(el)
        on('mousemove', onMouseMove)
        on('touchmove', onMouseMove)

        on('mouseenter', () => {
          hover.value = true
        })
        on('mouseleave', () => {
          hover.value = false
        })
        const focusParent = getFocusParent(el)
        if (focusParent) {
          const on = addEventListener(focusParent)
          on('mousedown', () => (active.value = true))
          on('mouseup', () => (active.value = false))
          on('mouseleave', () => {
            active.value = false
            focusParent.blur()
          })
          on('touchend', () => (active.value = false))
          on('touchcancel', () => (active.value = false))
          on('touchstart', () => focusParent.focus)
          on('focusin', () => {
            focus.value = true
          })
          on('focusout', () => {
            focus.value = false
            active.value = false
          })
        }
      }
    )
  )
}
