const agreement = document.getElementById('download-portal-agreement')
const buttons = document.querySelectorAll('.download-portal-files button')
if (agreement && buttons) {
  buttons.forEach((button) => {
    button.addEventListener('click', (e: Event) => {
      e.preventDefault()
      if (agreement.checked) {
        if (typeof ga === 'function') {
          ga('send', 'event', 'Protected Downloads', 'download', button.querySelector('.ce-uploads-fileName').innerText)
        }
        location.href = button.parentElement?.getAttribute('data-url') + button.getAttribute('data-id') + '/'
      } else {
        agreement.classList.add('is-invalid')
        agreement.focus()
      }
    })
  })

  agreement.addEventListener('change', () => {
    if (agreement.checked) {
      agreement.classList.remove('is-invalid')
      $('.download-portal-files').slideDown(300)
    } else {
      agreement.classList.add('is-invalid')
      $('.download-portal-files').slideUp(300)
    }
  })
}
