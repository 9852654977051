import watchElements from '../utils/watchElements'
import { animatedComponent } from '../utils/AnimatedValue'

watchElements(
  '.js-pointwave',
  {},
  animatedComponent(
    (el) => ({
      el,
      src: el.dataset.src
    }),
    () => {
      return import('./3d').then((x) => x.pointWave)
    }
  )
)

// watchElements(
//   '.js-pointcloud',
//   {},
//   animatedComponent(
//     (el) => ({
//       el,
//       src: el.dataset.src
//     }),
//     () => import('./3d').then((x) => x.pointCloud)
//   )
// )
