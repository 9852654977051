export type Style = Omit<
  CSSStyleDeclaration,
  | 'length'
  | 'parentRule'
  | 'setProperty'
  | 'removeProperty'
  | 'item'
  | 'getPropertyValue'
  | 'getPropertyPriority'
>
const polyfillStyles: { [key in keyof Style]?: string[] } = {
  transform: ['webkitTransform', 'msTransform']
}
export const setStyle = (
  el: HTMLElement | undefined | null,
  style: Partial<Style>
): void => {
  if (!el) {
    return
  }
  for (const [key, value] of Object.entries(style)) {
    el.style[key as keyof Style] = value || ''
  }
  for (const key in polyfillStyles) {
    if (key in style && typeof style[key] !== 'undefined') {
      for (const key2 of polyfillStyles[key]!) {
        el.style[key2 as keyof Style] = style[key]!
      }
    }
  }
}
