import watchElements from './utils/watchElements'
import {
  animatedComponent,
  AnimatedValue,
  LerpAnimation,
  InterpolatedValue
} from './utils/AnimatedValue'
import watchVisibility from './utils/animated/watchVisibility'

watchElements<HTMLElement>(
  '.count-up',
  {
    attributeFilter: ['data-ordinal']
  },
  animatedComponent(
    (el) => ({
      ordinal: 'ordinal' in el.dataset
    }),
    (el, args, mounted) => {
      const initialValue = args.value.ordinal ? 1 : 0
      const targetValue = Number(el.textContent?.replace(/[^\d]/g, ''))
      const value = new AnimatedValue(initialValue)
      new InterpolatedValue({ value, args }, ({ value, args }) => {
        const x = Math.round(value).toString()
        const lastChar = x.substr(x.length - 2, 1)
        if (!args.ordinal) {
          el.textContent = x
        } else {
          if (lastChar === '1') {
            el.textContent = x + 'st'
          } else if (lastChar === '2') {
            el.textContent = x + 'nd'
          } else if (lastChar === '3') {
            el.textContent = x + 'rd'
          } else {
            el.textContent = x + 'th'
          }
        }
      })
      const visible = watchVisibility(
        new AnimatedValue<Element | undefined>(el),
        mounted
      )
      visible.addListener((visible) => {
        if (visible) {
          value.value = initialValue
          new LerpAnimation(value, {
            toValue: targetValue,
            easing: 0.03
          }).start()
        } else {
        }
      }, true)
    }
  )
)
