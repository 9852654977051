import watchElements from './utils/watchElements'
import {
  animatedComponent,
  createDeferredValue,
  InterpolatedValue,
  Observable,
  pickOne,
  smoothen
} from './utils/AnimatedValue'
import watchBcr from './utils/animated/watchBcr'
import watchWindowSize from './utils/animated/watchWindowSize'
import { setStyle } from './utils/setStyle'

const IE11 =
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  navigator.appVersion.indexOf('Trident/') > -1

const getSvg = (src: Observable<string | false>) =>
  createDeferredValue(src, async (src) => {
    if (!src || IE11) {
      return undefined
    }
    const result = await fetch(src)
    if (result.headers.get('Content-Type') !== 'image/svg+xml') {
      throw new Error('Content is not an SVG')
    }
    const code = await result.text()
    const svgDoc = new DOMParser().parseFromString(code, 'image/svg+xml')
    for (const tag of svgDoc.getElementsByTagName('*')) {
      tag.removeAttribute('id')
      tag.removeAttribute('data-name')
    }
    const svg = svgDoc.documentElement
    svg.style.overflow = 'visible'
    return svg
  })

const getNumber = (x: string | null, defaultValue: number): number => {
  if (x === '0') {
    return 0
  }
  return Number(x) || defaultValue
}

watchElements<HTMLElement>(
  '.parallax-image, .parallax, .parallax-parent',
  {
    attributeFilter: ['data-speed', 'data-speed-delta', 'class']
  },
  animatedComponent(
    (el) => {
      const animateWrap = el.classList.contains('parallax')
      const animateChildren = el.classList.contains('parallax-parent')
      const img =
        !animateWrap && !animateChildren && el.getElementsByTagName('img')[0]
      const src = img && img.src
      return {
        animateWrap,
        animateChildren,
        src,
        speed: getNumber(el.getAttribute('data-speed'), 0.2),
        elementDelta: getNumber(el.getAttribute('data-speed-delta'), 0.05)
      }
    },
    (el, args, mounted) => {
      const svg = getSvg(pickOne(args, 'src'))
      svg.addListener((svg) => {
        if (svg && !(svg instanceof Error)) {
          const img = el.getElementsByTagName('img')[0]
          if (img) {
            setStyle(img, { visibility: 'hidden' })
          }
          el.appendChild(svg)
          return () => el.removeChild(svg)
        }
      })
      const element = new InterpolatedValue<
        Element | undefined,
        { svg: Error | HTMLElement | undefined }
      >({ svg }, ({ svg }) => {
        if (!svg || svg instanceof Error) {
          return el
        }
        return svg
      })
      const elements = new InterpolatedValue(
        { element, args },
        ({ element, args }) => {
          if (element && args.animateWrap) {
            return [element]
          }
          if (element && args.animateChildren) {
            const children = element.querySelectorAll<HTMLElement>(
              '.parallax-child'
            )
            const sortedChildren = Array.from(children).sort((ch1, ch2) => {
              const z1 = Number(getComputedStyle(ch1).zIndex)
              const z2 = Number(getComputedStyle(ch2).zIndex)
              return z1 > z2 ? 1 : z1 < z2 ? -1 : 0
            })
            return sortedChildren
          }
          const getElements = (root: Element): Element[] => {
            if (['svg', 'g'].includes(root.tagName.toLowerCase())) {
              let result: Element[] = []
              for (const child of root.children) {
                result = result.concat(getElements(child))
              }
              return result
            } else if (root === el) {
              return [...root.getElementsByTagName('img')].slice(0, 1)
            } else {
              return [root]
            }
          }
          if (!element) {
            return []
          }
          return getElements(element)
        }
      )
      const bcr = watchBcr(element, mounted)
      const centerOffset = smoothen(
        new InterpolatedValue(
          {
            bcr,
            windowSize: watchWindowSize(mounted)
          },
          ({ bcr, windowSize: { height } }) => {
            if (!bcr) {
              return 0
            }
            const { bottom, top } = bcr
            return Math.min(pageYOffset, (bottom + top) / 2 - height / 2)
          }
        ),
        0.5
      )
      const scale = new InterpolatedValue({ svg, bcr }, ({ svg, bcr }) => {
        if (!svg || svg instanceof Error || !bcr) {
          return 1
        }
        const viewBox = svg.getAttribute('viewBox')
        if (!viewBox) {
          return 1
        }
        const [, , , height] = viewBox.split(' ').map((x) => +x)
        return bcr.height / height
      })
      new InterpolatedValue(
        { elements, centerOffset, scale, args },
        ({ elements, centerOffset, scale, args }) => {
          elements.forEach((el, i) => {
            const translateY =
              (centerOffset || 0) * args.speed * (1 + args.elementDelta * i)
            if (el instanceof HTMLElement) {
              setStyle(el, {
                transform: `translateY(${
                  Math.round(translateY * scale * window.devicePixelRatio) /
                  window.devicePixelRatio
                }px)`
              })
            } else {
              const transform =
                el.getAttribute('transform-original') ||
                el.getAttribute('transform') ||
                ''
              if (el.getAttribute('transform-original') === null) {
                el.setAttribute('transform-original', transform)
              }
              const translate = `translate(0, ${translateY * scale}) `
              el.setAttribute('transform', translate + transform)
            }
          })
        }
      )
    }
  )
)
