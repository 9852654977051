import { AnimatedValue, Observable } from '../AnimatedValue'

const watchWindowScroll = (
  mounted: Observable<boolean>
): AnimatedValue<{ x: number; y: number }> => {
  const scrollPosition = new AnimatedValue({
    x: window.pageXOffset,
    y: window.pageYOffset
  })
  const onScroll = () => {
    scrollPosition.value = {
      x: window.pageXOffset,
      y: window.pageYOffset
    }
  }

  mounted.addListener((mounted) => {
    if (mounted) {
      window.addEventListener('resize', onScroll)
      window.addEventListener('scroll', onScroll, { passive: false })
      return () => {
        window.removeEventListener('resize', onScroll)
        window.removeEventListener('scroll', onScroll)
      }
    }
  }, true)

  return scrollPosition
}

export default watchWindowScroll
