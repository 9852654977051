function tableSrollArrow() {
  const scrollArrows = document.querySelectorAll('.table-scroll-arrow-outer')
  if (scrollArrows) {
    for (const scrollArrow of scrollArrows) {
      const parentDiv = scrollArrow.closest('.table-responsive')
      const parentTable = scrollArrow.closest('table')
      if (parentDiv && parentTable) {
        const parentDivWidth = parentDiv.clientWidth
        const parentTableWidth = parentTable.clientWidth
        if (
          parentDivWidth &&
          parentTableWidth &&
          parentDivWidth < parentTableWidth
        ) {
          scrollArrow.classList.remove('d-none')
        } else {
          scrollArrow.classList.add('d-none')
        }
      }
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  tableSrollArrow()
})

window.addEventListener(
  'resize',
  () => {
    tableSrollArrow()
  },
  { passive: true }
)
