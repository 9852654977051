import watchElements from './utils/watchElements'
import { animatedComponent, InterpolatedValue } from './utils/AnimatedValue'
import watchWindowSize from './utils/animated/watchWindowSize'
import watchWindowScroll from './utils/animated/watchWindowScroll'
import { setStyle } from './utils/setStyle'

watchElements<HTMLDivElement>(
  '#scroll-hint',
  {},
  animatedComponent(
    () => ({}),
    (el, _args, mounted) => {
      const windowHeight = watchWindowSize(mounted).interpolate((x) => x.height)
      const scrollTop = watchWindowScroll(mounted).interpolate((pos) => pos.y)
      const opacity = new InterpolatedValue(
        { windowHeight, scrollTop },
        ({ windowHeight, scrollTop }) => {
          return Math.min(1, Math.max(0, 1 - (scrollTop / windowHeight) * 2))
        }
      )
      opacity.addListener(
        (opacity) => setStyle(el, { opacity: opacity + '' }),
        true
      )
    }
  )
)
