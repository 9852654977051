import { Observable, AnimatedValue } from '../AnimatedValue'

const watchWindowSize = (
  mounted: Observable<boolean>
): AnimatedValue<{ width: number; height: number }> => {
  const result = new AnimatedValue({
    width: window.innerWidth,
    height: window.innerHeight
  })
  const update = () => {
    result.value = {
      width: window.innerWidth,
      height: window.innerHeight
    }
  }
  mounted.addListener((mounted) => {
    if (mounted) {
      window.addEventListener('resize', update)
      return () => window.removeEventListener('resize', update)
    }
  }, true)

  return result
}

export default watchWindowSize
