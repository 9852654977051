import { Observable, AnimatedValue } from '../AnimatedValue'
import ResizeObserver from 'resize-observer-polyfill'

const watchElementSize = <T extends Element>(
  el: Observable<T | undefined> | Observable<T>
): AnimatedValue<{ width: number; height: number }> => {
  const result = new AnimatedValue({
    width: 0,
    height: 0
  })
  const update = (el: Element) => {
    const { width, height } = el.getBoundingClientRect()
    result.value = {
      width,
      height
    }
  }
  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      update(entry.target)
    }
  })
  el.addListener((el) => {
    if (!el) {
      return
    }
    resizeObserver.observe(el)
    update(el)
    return () => {
      resizeObserver.unobserve(el)
    }
  }, true)

  return result
}

export default watchElementSize
