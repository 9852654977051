import watchElements from './utils/watchElements'
import {
  animatedComponent,
  AnimatedValue,
  InterpolatedValue
} from './utils/AnimatedValue'
import watchVisibility from './utils/animated/watchVisibility'
import toggleClass from './utils/toggleClass'

watchElements(
  'video[data-autoplay]',
  {
    attributeFilter: ['data-autoplay', 'data-volumecontrols', 'data-playpause']
  },
  animatedComponent<
    { volumecontrols?: string; playpause?: string },
    HTMLVideoElement
  >(
    (el) => ({
      volumecontrols: el.dataset.volumecontrols,
      playpause: el.dataset.playpause
    }),
    (el, args, mounted, addEventListener) => {
      el.preload = 'auto'
      const canPlay = new AnimatedValue(el.readyState >= 1)
      const visible = watchVisibility(el, mounted)
      const playing = new AnimatedValue(false)
      const muted = new AnimatedValue(el.muted)
      addEventListener(el)('volumechange', () => {
        muted.value = el.muted || el.volume === 0
      })
      const btn = document.createElement('button')
      btn.setAttribute('class', 'btn btn-mute-video')
      btn.addEventListener('click', () => {
        muted.value = !muted.value
      })
      args
        .interpolate((x) => x.volumecontrols)
        .addListener((volumecontrols) => {
          if (volumecontrols && volumecontrols.trim()) {
            el.parentElement &&
              el.parentElement.insertBefore(btn, el.nextSibling)
            return () => {
              btn.parentElement && btn.parentElement.removeChild(btn)
            }
          }
        })
      muted.addListener((muted) => {
        if (muted !== (el.muted || el.volume < 0.1)) {
          el.muted = muted
          if (!muted) {
            el.volume = Math.max(el.volume, 0.1)
          }
        }
        toggleClass(btn, 'is-muted', muted)
      })

      const paused = new AnimatedValue(false)
      addEventListener(el)('click', () => {
        paused.value = !paused.value
        if (paused.value) el.pause()
        const div = document.createElement('div')
        div.setAttribute(
          'class',
          `video-hint video-hint-${paused.value ? 'pause' : 'play'}`
        )
        for (const hint of el.parentElement!.querySelectorAll('.video-hint')) {
          hint.parentElement!.removeChild(hint)
        }
        el.parentElement!.appendChild(div)
        setTimeout(() => el.parentElement!.removeChild(div), 600)
      })

      addEventListener(el)('loadedmetadata', () => {
        canPlay.value = true
      })
      addEventListener(el)('canplay', () => {
        canPlay.value = true
      })
      addEventListener(el)('canplaythrough', () => {
        canPlay.value = true
      })
      addEventListener(el)('playing', () => {
        playing.value = true
      })
      addEventListener(el)('pause', () => {
        playing.value = false
      })
      addEventListener(el)('ended', () => {
        playing.value = false
      })
      const shouldPlay = new InterpolatedValue(
        { canPlay, visible, paused },
        ({ canPlay, visible, paused }) => canPlay && visible && !paused
      )
      new InterpolatedValue(
        { shouldPlay, playing },
        ({ shouldPlay, playing }) => {
          if (shouldPlay && !playing) {
            el.play()
          } else if (!shouldPlay && playing) {
            el.pause()
          }
        }
      )
    }
  )
)
