import { onMeasure, AnimationFrameListener } from './animated/onMeasure'

const lerp = (a: number, b: number, n: number) => (1 - n) * a + n * b

type Listener<T = number> = (
  value: T,
  oldValue: T | undefined
) => void | (() => void)

interface Animation {
  start: () => void
  _stop: () => void
}

export class Observable<T = number> {
  _listeners: Listener<T>[] = []
  _cancelListeners: (() => void)[] = []
  _lastValue: T | undefined
  _value: T

  get value(): T {
    return this._value
  }

  constructor(value: T) {
    this._value = value
  }

  log(name: string): this {
    return this.addListener((x) => console.log(name, x), true)
  }

  addListener(listener: Listener<T>, invokeImmediately = true): this {
    if (!this._listeners.includes(listener)) {
      this._listeners.push(listener)
      if (invokeImmediately) {
        const result = listener(this.value, this._lastValue)
        if (result) {
          this._cancelListeners.push(result)
        }
      }
    }
    return this
  }

  removeListener(listener: Listener<T>): this {
    this._listeners = this._listeners.filter((x) => x !== listener)
    return this
  }

  removeAllListeners(): this {
    this._listeners = []
    return this
  }

  _equals(a: unknown, b: unknown, deep = true): boolean {
    if (Array.isArray(a) && Array.isArray(b) && deep) {
      if (a.length !== b.length) {
        return false
      }
      for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
          return false
        }
      }
      return true
    }
    if (typeof a === 'object' && typeof b === 'object' && deep) {
      if (a === null) {
        return b === null
      }
      if (b === null) {
        return a === null
      }
      if (Object.keys(a).length !== Object.keys(b).length) {
        return false
      }
      for (const key in a) {
        if (
          !this._equals(
            a[key as keyof typeof a],
            b[key as keyof typeof b],
            false
          )
        ) {
          return false
        }
      }
      return true
    }
    return a === b
  }

  _setValue(value: T): void {
    if (typeof value === 'number' && isNaN(value)) {
      throw new Error('Value is NaN')
    }
    if (this._equals(this._value, value)) {
      return
    }
    this._cancelListeners.forEach((x) => x())
    this._cancelListeners = []
    this._lastValue = this._value
    this._value = value
    for (const listener of this._listeners) {
      const result = listener(this._value, this._lastValue)
      if (result) {
        this._cancelListeners.push(result)
      }
    }
  }

  interpolate = <R>(interpolationFunction: (input: T) => R): Observable<R> => {
    return new InterpolatedValue({ x: this }, ({ x }) =>
      interpolationFunction(x)
    )
  }
}

export class AnimatedValue<T = number> extends Observable<T> {
  get value(): T {
    return this._value
  }

  set value(nextValue: T) {
    this._setValue(nextValue)
  }

  _animation?: Animation
  _animationStopped(animation: Animation): void {
    if (animation === this._animation) {
      this._animation = undefined
    }
  }
  _animationStart(animation: Animation): void {
    if (this._animation && this._animation !== animation) {
      this._animation._stop()
    }
    this._animation = animation
  }
  stop(): void {
    if (this._animation) {
      this._animation._stop()
    }
  }
}

export const createDeferredValue = <T, Input>(
  value: Observable<Input>,
  interpolate: (values: Input) => Promise<T>
): Observable<T | Error | undefined> => {
  const result = new AnimatedValue<T | Error | undefined>(undefined)
  value.addListener((value) => {
    let cancelled = false
    interpolate(value)
      .then((x) => {
        if (!cancelled) {
          result.value = x
        }
      })
      .catch((e) => {
        if (!cancelled) {
          result.value = new Error(e)
        }
      })
    return () => {
      cancelled = true
    }
  }, true)

  return result
}

export const pickOne = <
  T extends { [key: string]: unknown },
  K extends keyof T
>(
  value: Observable<T>,
  key: K
): Observable<T[K]> =>
  new InterpolatedValue({ value }, ({ value }) => value[key])

export class InterpolatedValue<
  T,
  Input extends { [name: string]: unknown }
> extends Observable<T> {
  _oldValues: Input | undefined
  _values: Input
  _interpolate: (values: Input, oldValues: Input | undefined) => T

  get value(): T {
    return this._value
  }

  constructor(
    values: { [key in keyof Input]: Observable<Input[key]> },
    interpolate: (values: Input, oldValues: Input | undefined) => T
  ) {
    const _values: Partial<Input> = {}
    for (const [key, observable] of Object.entries(values)) {
      _values[key as keyof Input] = observable.value
    }
    super(interpolate(_values as Input, undefined))
    this._interpolate = interpolate
    this._values = _values as Input
    for (const [key, observable] of Object.entries(values)) {
      observable.addListener((x: Input[string]) => this.onValueChange(key, x))
    }
  }

  onValueChange<T extends keyof Input>(index: T, value: Input[T]): void {
    this._oldValues = { ...this._values }
    if (this._values[index] === value) {
      return
    }
    this._values[index] = value
    this._setValue(this._interpolate(this._values, this._oldValues))
  }
}

export const roundAnimation = (
  value: Observable
): InterpolatedValue<number, { original: number }> => {
  return new InterpolatedValue({ original: value }, ({ original }) =>
    Math.round(original)
  )
}

export class LerpAnimation implements Animation {
  _animatedValue: AnimatedValue<number>
  _toValue?: number
  _easing: number

  constructor(
    value: AnimatedValue<number>,
    { toValue, easing = 0.2 }: { toValue?: number; easing?: number } = {}
  ) {
    this._animatedValue = value
    this._toValue = toValue
    this._easing = easing
  }

  _af?: number
  start(toValue?: number): void {
    if (typeof toValue === 'number') {
      this._toValue = toValue
    }
    if (typeof this._af !== 'number') {
      onMeasure(this._onMeasure)
    }
    this._animatedValue._animationStart(this)
  }
  _stop(): void {
    if (typeof this._af === 'number') {
      cancelAnimationFrame(this._af)
    }
    this._animatedValue._animationStopped(this)
  }

  _onAnimationFrame = (): boolean => {
    if (typeof this._toValue === 'undefined') {
      this._animatedValue._animationStopped(this)
      return false
    }
    const toValue = this._toValue
    const current = this._animatedValue._value
    if (Math.abs(toValue - current) < 1e-3) {
      this._animatedValue.value = toValue
    } else {
      const nextValue = lerp(current, toValue, this._easing)
      this._animatedValue.value = nextValue
    }
    if (this._animatedValue.value === toValue) {
      this._animatedValue._animationStopped(this)
      return false
    }
    return true
  }

  _onMeasure: AnimationFrameListener<undefined> = {
    measure: () => undefined,
    update: this._onAnimationFrame
  }
}

export const smoothen = (
  value: Observable<number>,
  easing = 0.2
): AnimatedValue<number> => {
  const result = new AnimatedValue<number>(value.value)
  const animation = new LerpAnimation(result, { easing })
  let enabled = document.readyState === 'complete'
  value.addListener((value) => {
    if (enabled) {
      animation.start(value)
    } else {
      result.value = value
    }
  }, true)
  if (!enabled) {
    document.addEventListener('readystatechange', () => {
      if (document.readyState === 'complete') {
        result.value = value.value
        enabled = true
      }
    })
  }

  return result
}

type AddEventListenerFunction<El extends HTMLElement = HTMLElement> = (
  el: El
) => El['addEventListener']

export type AnimatedComponentInit<T, El extends HTMLElement = HTMLElement> = (
  el: El,
  args: T extends undefined ? undefined : AnimatedValue<T>,
  mounted: AnimatedValue<boolean>,
  addEventListener: <El2 extends HTMLElement = HTMLElement>(
    el: El2
  ) => El2['addEventListener']
) => void | (() => void)

export const animatedComponent = <T, El extends HTMLElement = HTMLElement>(
  getArgs: T extends undefined ? undefined : (el: El) => T,
  init:
    | AnimatedComponentInit<T, El>
    | (() => Promise<AnimatedComponentInit<T, El>>)
): {
  mount: (
    el: El
  ) => {
    update: () => void
    unmount: () => void
  }
} => ({
  mount: (el: El) => {
    const args = (typeof getArgs !== 'undefined'
      ? new AnimatedValue(getArgs(el))
      : undefined) as T extends undefined ? undefined : AnimatedValue<T>
    const mounted = new AnimatedValue(true)
    const addEventListener: AddEventListenerFunction = <
      El2 extends HTMLElement = HTMLElement
    >(
      el: El2
    ) => (...args: Parameters<El2['addEventListener']>): void => {
      mounted.addListener((mounted) => {
        if (!mounted) {
          el.removeEventListener(args[0], args[1])
        } else {
          el.addEventListener(args[0], args[1], args[2])
        }
      }, true)
    }

    const res = init(el, args, mounted, addEventListener)
    if (res && 'then' in res) {
      const onUnmount = res.then((x) => x(el, args, mounted, addEventListener))
      return {
        update: () => {
          if (args && getArgs) {
            args.value = getArgs(el)
          }
        },
        unmount: () => {
          mounted.value = false
          if (onUnmount) {
            onUnmount.then((x) => x && x())
          }
        }
      }
    } else {
      const onUnmount = res
      return {
        update: () => {
          if (args && getArgs) {
            args.value = getArgs(el)
          }
        },
        unmount: () => {
          mounted.value = false
          if (onUnmount) {
            onUnmount()
          }
        }
      }
    }
  }
})
