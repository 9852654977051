import watchElements from './utils/watchElements'
import {
  AnimatedValue,
  InterpolatedValue,
  smoothen,
  animatedComponent,
  Observable
} from './utils/AnimatedValue'
import { setStyle, Style } from './utils/setStyle'
import toNumber from './utils/toNumber'
import fit from './utils/fit'

const range = (length: number): number[] =>
  Array.from(Array(length)).map((_, x) => x)

const loadedImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.onabort = image.onerror = () => {
      reject(`Image ${src} could not be loaded`)
    }
    image.src = src
  })

const canUseWebP = (() => {
  const elem = document.createElement('canvas')

  if (!!(elem.getContext && elem.getContext('2d'))) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  }

  // very old browser like IE 8, canvas not supported
  return false
})()

const videoCache = (
  args: AnimatedValue<ScrollVideoArgs>,
  mobileHidden: Observable<boolean>
): AnimatedValue<HTMLImageElement[]> => {
  const cache = new AnimatedValue<HTMLImageElement[]>([])

  new InterpolatedValue({ args, mobileHidden }, ({ args, mobileHidden }) => ({
    ...args,
    mobileHidden
  })).addListener(({ src, frames, webp, mobileHidden }) => {
    let cancelled = false
    const extension = webp && canUseWebP ? '.webp' : '.jpg'
    cache.value = []
    if (!mobileHidden && src && frames) {
      const path = src.endsWith('/') ? src : src + '/'
      const filenamelength = frames.toString().length
      range(frames).map((i) => {
        const url =
          path + String(i + 1).padStart(filenamelength, '0') + extension
        loadedImage(url).then((image) => {
          if (!cancelled) {
            const nextCache = [...cache.value]
            nextCache[i] = image
            cache.value = nextCache
          }
        })
      })
    }
    return () => {
      cancelled = true
    }
  }, true)

  return cache
}

const getPosition = (el: HTMLElement) => {
  const { top, left, width, height } = el.getBoundingClientRect()
  return { top, left, width, height }
}

type ScrollVideoArgs = {
  src: string | undefined
  frames: number
  playbackSpeed: number
  focusPoint: { x: number; y: number }
  webp: boolean
  proportionsRange: [number, number]
  contents: {
    element: HTMLElement
    showAt: number
    hideAt: number
    fadeInDuration: number
    fadeOutDuration: number
  }[]
}
const ScrollVideo = (
  wrap: HTMLElement,
  args: AnimatedValue<ScrollVideoArgs>,
  mounted: AnimatedValue<boolean>
) => {
  const innerWrap = document.createElement('div')
  for (const child of Array.from(wrap.children)) {
    wrap.removeChild(child)
    innerWrap.appendChild(child)
  }
  wrap.appendChild(innerWrap)

  const canvas = document.createElement('canvas')
  innerWrap.prepend(canvas)
  const ctx = canvas.getContext('2d')!

  const position = new AnimatedValue(getPosition(wrap))
  const scrollPosition = new AnimatedValue(window.pageYOffset)
  const smoothScrollPosition = smoothen(scrollPosition)
  const windowHeight = new AnimatedValue(window.innerHeight)
  const onScroll = () => {
    scrollPosition.value = window.pageYOffset
    position.value = getPosition(wrap)
    windowHeight.value = window.innerHeight
  }
  window.addEventListener('resize', onScroll)
  window.addEventListener('scroll', onScroll, { passive: false })

  const mobileHidden = new InterpolatedValue(
    { position, windowHeight, args },
    ({ position, windowHeight, args }) => {
      const proportions = position.width / windowHeight
      const [min, max] = args.proportionsRange
      return proportions >= max || proportions < min
    }
  )

  const video = videoCache(args, mobileHidden)

  const frameSize = new AnimatedValue({ width: 0, height: 0 }).addListener(
    ({ width, height }) => {
      canvas.width = width
      canvas.height = height
      setStyle(canvas, {
        width: width + 'px',
        height: height + 'px'
      })
    }
  )

  window.addEventListener('resize', onScroll)
  window.addEventListener('scroll', onScroll, { passive: false })

  const currentFrame = new InterpolatedValue(
    {
      args,
      position,
      scrollPosition,
      smoothScrollPosition
    },
    ({
      args: { frames, playbackSpeed },
      position,
      scrollPosition,
      smoothScrollPosition
    }) => {
      return Math.floor(
        Math.max(
          0,
          Math.min(
            frames - 1,
            -(position.top + scrollPosition - smoothScrollPosition) /
              playbackSpeed
          )
        )
      )
    }
  )
  new InterpolatedValue({ currentFrame, video }, ({ currentFrame, video }) => {
    if (video[currentFrame]) {
      return video[currentFrame]
    }
  }).addListener((image) => {
    if (!image) {
      return
    }
    if (
      canvas.width !== image.naturalWidth ||
      canvas.height !== image.naturalHeight
    ) {
      frameSize.value = {
        width: image.naturalWidth,
        height: image.naturalHeight
      }
    }
    ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
  }, true)

  new InterpolatedValue({ args, mobileHidden }, ({ args, mobileHidden }) => {
    if (mobileHidden) {
      return {
        height: '0px',
        paddingBottom: '0px',
        visibility: 'hidden',
        opacity: '0',
        pointerEvents: 'none'
      }
    } else {
      return {
        height: '100vh',
        paddingBottom: args.playbackSpeed * args.frames + 'px',
        visibility: 'visible',
        opacity: '1',
        pointerEvents: 'all'
      }
    }
  }).addListener((style) => {
    setStyle(wrap, style)
  })

  new InterpolatedValue(
    {
      position,
      windowHeight,
      frameSize,
      focusPoint: args.interpolate((x) => x.focusPoint)
    },
    ({ position, windowHeight, frameSize, focusPoint }): Partial<Style> => {
      const { offset, size } = fit(
        'cover',
        { width: position.width, height: windowHeight },
        frameSize,
        focusPoint
      )
      return {
        marginLeft: offset.left + 'px',
        marginTop: offset.top + 'px',
        width: size.width + 'px',
        height: size.height + 'px'
      }
    }
  ).addListener((style) => setStyle(canvas, style), true)

  new InterpolatedValue(
    { position, args, mobileHidden },
    ({ position, args: { frames, playbackSpeed }, mobileHidden }) => {
      let className = 'inner-wrap'
      if (mobileHidden || position.top > 0) {
      } else if (-position.top < playbackSpeed * frames) {
        className += ' fixed'
      } else {
        className += ' bottom'
      }
      return className
    }
  ).addListener((className) => innerWrap.setAttribute('class', className), true)

  const contents = args.interpolate((x) => x.contents)
  const contentElements = contents.interpolate((x) => x.map((y) => y.element))
  const contentOpacities = new InterpolatedValue(
    { contents, currentFrame },
    ({ contents, currentFrame }) => {
      return contents.map(
        ({ showAt, hideAt, fadeInDuration, fadeOutDuration }) => {
          if (currentFrame < showAt || currentFrame > hideAt) {
            return 0
          }
          const fadeIn = Math.min(
            1,
            Math.max(0, (currentFrame - showAt) / fadeInDuration)
          )
          const fadeOut = Math.min(
            1,
            Math.max(0, (hideAt - currentFrame) / fadeOutDuration)
          )
          return fadeIn * fadeOut
        }
      )
    }
  )
  new InterpolatedValue(
    { contentOpacities, contentElements },
    ({ contentOpacities, contentElements }) =>
      contentElements.map((element, i) => ({
        element,
        opacity: contentOpacities[i]
      }))
  ).addListener((items) => {
    items.forEach(({ element, opacity }) => {
      setStyle(element, {
        opacity: opacity.toString(),
        visibility: opacity === 0 ? 'hidden' : 'visible'
      })
    })
  })

  mounted.addListener((mounted) => {
    if (!mounted) {
      window.removeEventListener('resize', onScroll)
      window.removeEventListener('scroll', onScroll)
    }
  })
}

const init = () => {
  watchElements<HTMLElement>(
    '.scroll-video',
    {
      attributeFilter: [
        'data-src',
        'data-frames',
        'data-playback-speed',
        'data-content-visibility',
        'data-content-visibility-frames'
      ]
    },
    animatedComponent<ScrollVideoArgs>((el) => {
      const dataFocusPoint = (el.dataset.focusPoint || '')
        .split(',')
        .map((x) => x.trim())
      const focusPoint = {
        x: toNumber(dataFocusPoint[0], 50) / 100,
        y: toNumber(dataFocusPoint[1], 50) / 100
      }
      return {
        src: el.dataset.src,
        frames: toNumber(el.dataset.frames),
        playbackSpeed: toNumber(el.dataset.playbackSpeed, 10),
        webp: el.dataset.webp === 'enabled',
        focusPoint,
        proportionsRange: [
          toNumber(el.dataset.minProportions, -Infinity),
          toNumber(el.dataset.maxProportions, Infinity)
        ],
        contents: ([...el.children] as HTMLElement[])
          .filter((element) => element.classList.contains('contents'))
          .map((element) => ({
            element,
            showAt: toNumber(element.dataset.showAt, -Infinity),
            hideAt: toNumber(element.dataset.hideAt, Infinity),
            fadeInDuration: toNumber(element.dataset.fadeInDuration, 20),
            fadeOutDuration: toNumber(element.dataset.fadeOutDuration, 20)
          }))
      }
    }, ScrollVideo)
  )
}
setTimeout(() => init())
