import watchWindowScroll from './utils/animated/watchWindowScroll'
import {
  AnimatedValue,
  InterpolatedValue,
  smoothen
} from './utils/AnimatedValue'
import { setStyle } from './utils/setStyle'
import toggleClass from './utils/toggleClass'

const menuOpen = new AnimatedValue(false)
const sidebarHover = new AnimatedValue(false)

const logo = document.querySelector<HTMLElement>('#page-header > .logo')
const scrollPos = watchWindowScroll(
  new AnimatedValue<boolean>(true)
).interpolate(({ y }) => y)
const scrollDirection = new AnimatedValue<'up' | 'down'>('down')
scrollPos.addListener((value, oldValue = 0) => {
  scrollDirection.value = value >= oldValue ? 'down' : 'up'
})
const logoOpacity = new InterpolatedValue(
  { scrollPos, scrollDirection, menuOpen },
  ({ scrollPos, scrollDirection, menuOpen }) => {
    if (scrollDirection === 'up' || menuOpen) {
      return 1
    }
    return Math.min(1, Math.max(0, 1 - scrollPos / 100))
  }
)
smoothen(logoOpacity).addListener((opacity) => {
  setStyle(logo, { opacity: opacity.toString() })
})

menuOpen.addListener((menuOpen) => {
  toggleClass(document.body, 'menu-open', menuOpen)
})

const mainMenu = document.querySelector('ul.mainmenu')
const updateSubmenus = (): void => {
  if (!mainMenu) {
    return
  }
  let shouldBreak = false
  for (const child of mainMenu.children) {
    if (child.children.length > 1) {
      child.children[1].classList.remove('w-100')
    }
  }
  for (const child of mainMenu.children) {
    if (child.children.length > 1) {
      if (
        child.children[1].getBoundingClientRect().left <=
        child.getBoundingClientRect().left
      ) {
        shouldBreak = true
        break
      }
    }
  }
  for (const child of mainMenu.children) {
    if (child.children.length > 1) {
      toggleClass(child.children[1] as HTMLElement, 'w-100', shouldBreak)
    }
  }
}
updateSubmenus()
window.addEventListener('resize', updateSubmenus, { passive: true })

const menubutton = document.getElementById('menubutton')
if (menubutton) {
  menubutton.addEventListener('click', (e) => {
    e.preventDefault()
    e.stopPropagation()
    ;(e.target as HTMLElement).closest('button')?.blur()
    menuOpen.value = !menuOpen.value
  })
  menubutton.addEventListener('mouseenter', () => (sidebarHover.value = true))
  menubutton.addEventListener('touchmove', () => (sidebarHover.value = true))
  menubutton.addEventListener('mouseleave', () => (sidebarHover.value = false))
  menubutton.addEventListener('touchend', () => (sidebarHover.value = false))
  menubutton.addEventListener('touchcancel', () => (sidebarHover.value = false))

  for (const el of menubutton.getElementsByTagName('svg')) {
    menubutton.removeChild(el)
  }
  import('./animations/lottie').then((x) => {
    x.enableMenuButton(menubutton, menuOpen, sidebarHover)
  })
}
