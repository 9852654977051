const fit = (
  mode: 'cover' | 'contain',
  container: { width: number; height: number },
  child: { width: number; height: number },
  focusPointPct: { x: number; y: number } = { x: 0.5, y: 0.5 },
  extraOffset: { left: number; top: number } = { left: 0, top: 0 }
): {
  size: { width: number; height: number }
  offset: { left: number; top: number }
} => {
  const imgRatio = child.height / child.width
  const ctxRatio = container.height / container.width
  const alignByWidth =
    (mode === 'cover' && imgRatio > ctxRatio) ||
    (mode !== 'cover' && imgRatio < ctxRatio)
  const size = alignByWidth
    ? {
        width: container.width,
        height: Math.ceil(container.width * imgRatio)
      }
    : {
        width: Math.ceil(container.height / imgRatio),
        height: container.height
      }
  const offset = {
    left: (container.width - size.width) * focusPointPct.x + extraOffset.left,
    top: (container.height - size.height) * focusPointPct.y + extraOffset.top
  }
  return {
    size,
    offset
  }
}

export default fit
