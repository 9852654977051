import watchElements from './utils/watchElements'
import {
  animatedComponent,
  InterpolatedValue,
  smoothen
} from './utils/AnimatedValue'
import watchBcr from './utils/animated/watchBcr'
import watchWindowSize from './utils/animated/watchWindowSize'
import watchVisibility from './utils/animated/watchVisibility'
import toggleClass from './utils/toggleClass'

const getPct = (min: number, max: number, position: number): number => {
  return Math.min(1, Math.max(0, (position - min) / (max - min))) || 0
}

const easing = 0.05
let animated = false

watchElements(
  '.watch-scroll',
  {},
  animatedComponent(
    () => ({}),
    (el, _args, mounted) => {
      const bcr = watchBcr(el, mounted)
      const windowSize = watchWindowSize(mounted)
      smoothen(
        new InterpolatedValue({ bcr, windowSize }, ({ bcr, windowSize }) => {
          if (!bcr) {
            return 0
          }
          return getPct(-bcr.height, windowSize.height, bcr.top) * 2 - 1
        }),
        easing
      ).addListener((pct) => {
        el.style.setProperty('--visible-pct', pct.toString())
        el.style.setProperty(
          '--visible-in-center-pct',
          Math.max(0, 1 - pct).toString()
        )
        el.style.setProperty(
          '--visible-out-center-pct',
          (1 - Math.max(0, -pct)).toString()
        )
      })
      smoothen(
        new InterpolatedValue({ bcr, windowSize }, ({ bcr, windowSize }) => {
          if (!bcr) {
            return 0
          }
          return getPct(
            windowSize.height,
            windowSize.height - bcr.height,
            bcr.top
          )
        }),
        easing
      ).addListener((pct) => {
        el.style.setProperty('--visible-in-pct', pct.toString())
      })
      smoothen(
        new InterpolatedValue({ bcr }, ({ bcr }) => {
          if (!bcr) {
            return 0
          }
          return getPct(-bcr.height, 0, bcr.top)
        }),
        easing
      ).addListener((pct) => {
        el.style.setProperty('--visible-out-pct', pct.toString())
      })
    }
  )
)

watchElements(
  '.watch-visibility, .content-text p, .content-text li, .content-text h1, .content-text h2, .content-text h3, .content-text h4, .content-text h5, .content-text h6',
  {},
  animatedComponent(
    () => ({}),
    (el, _args, mounted, addListener) => {
      const visibility = watchVisibility(el, mounted)
      visibility.addListener((visible) => {
        toggleClass(el, 'is-visible', visible)
        if (!animated && visible) {
          el.classList.add('was-visible')
        } else if (visible) {
          setTimeout(() => {
            el.classList.add('was-visible')
          }, 200)
        }
      })
    }
  )
)

document.addEventListener('readystatechange', () => {
  if (document.readyState === 'complete') {
    document.body.classList.add('animated')
    animated = true
  }
})
