import watchBcr from './utils/animated/watchBcr'
import { animatedComponent, InterpolatedValue } from './utils/AnimatedValue'
import { setStyle } from './utils/setStyle'
import watchElements from './utils/watchElements'

watchElements(
  '.collage2',
  {},
  animatedComponent(
    (el) => ({
      img: el.querySelector<HTMLElement>('img') || undefined,
      content: el.querySelector<HTMLElement>('.content') || undefined
    }),
    (el, args, mounted) => {
      const imageHeight = watchBcr<HTMLElement>(
        args.interpolate((x) => x.img),
        mounted
      ).interpolate((x) => (x ? x.height : 0))
      new InterpolatedValue({ imageHeight, args }, ({ imageHeight, args }) => {
        setStyle(args.content, { minHeight: imageHeight + 'px' })
      })
    }
  )
)
