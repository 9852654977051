import watchElements from './utils/watchElements'
import { onUpdate } from './utils/animated/onMeasure'

watchElements<HTMLElement>(
  '.color-gradient .section-header',
  {
    watchResize: true
  },
  {
    update(el) {
      const { backgroundSize, width: beforeWidth } = window.getComputedStyle(
        el,
        ':before'
      )
      const lineWidth = Number(backgroundSize.split(' ')[0].replace('px', ''))
      const offset = Number(beforeWidth.replace('px', '')) - lineWidth || 0
      const { width } = el.getBoundingClientRect()
      el.style.setProperty('--gradient-offset', -offset + 'px')
      el.style.setProperty('--gradient-width', width + 'px')
    }
  }
)

const MAX_PAN = 0.1

const sidebar = document.querySelector<HTMLElement>('#mainmenu .sidebar')

window.addEventListener('mousemove', (e) => {
  const pct = Math.min(1, Math.max(0, e.clientX / innerWidth))
  const pan = (pct - 0.5) * MAX_PAN * 2
  onUpdate(() => {
    document.body.style.setProperty('--gradient-panx', String(pan))
    if (sidebar) {
      sidebar.style.setProperty(
        '--gradient-pany',
        String(-e.clientY / innerHeight)
      )
    }
  })
})

window.addEventListener('touchstart', (e) => {
  if (sidebar) {
    sidebar.style.setProperty(
      '--gradient-pany',
      String(-e.touches[0].clientY / innerHeight)
    )
  }
})

window.addEventListener('touchmove', (e) => {
  if (sidebar) {
    sidebar.style.setProperty(
      '--gradient-pany',
      String(-e.touches[0].clientY / innerHeight)
    )
  }
})
