import { InterpolatedValue, Observable, AnimatedValue } from '../AnimatedValue'
import watchVisibility from './watchVisibility'
import { onMeasure } from './onMeasure'

export type BcrMinimal = {
  top: number
  left: number
  bottom: number
  right: number
  width: number
  height: number
}
const getBcrMinimal = (el: Element | undefined): BcrMinimal | undefined => {
  if (!el || el instanceof Error) {
    return undefined
  }
  const { top, left, bottom, right, width, height } = el.getBoundingClientRect()
  return { top, left, bottom, right, width, height }
}

const watchBcr = <T extends Element>(
  _el: T | Observable<T | undefined> | Observable<T>,
  mounted: Observable<boolean>
): AnimatedValue<BcrMinimal | undefined> => {
  const el =
    !_el || _el instanceof Element || !('value' in _el)
      ? new AnimatedValue(_el)
      : _el
  const result = new AnimatedValue<BcrMinimal | undefined>(
    getBcrMinimal(el.value)
  )
  const visible = watchVisibility<T>(el, mounted)
  new InterpolatedValue(
    { el: el as Observable<Element | undefined>, visible },
    ({ el, visible }) => {
      if (!el || el instanceof Error) {
        return undefined
      }
      return el
    }
  ).addListener((el) => {
    if (el) {
      let mounted = true
      onMeasure({
        measure: () => getBcrMinimal(el),
        update: (x) => {
          result.value = x
          return mounted
        }
      })
      return () => {
        mounted = false
      }
    }
  }, true)

  return result
}

export default watchBcr
